import "./App.css";

function App() {
  return (
    <div className="bg-orange-800 flex items-center justify-center h-screen">
      <section class="">
        <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div class="mx-auto max-w-screen-sm text-center">
            <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">
              404
            </h1>
            <p class="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white uppercase">
              Az oldal nem található
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
